import { lazy } from 'react';
import BizmixLayout from '../Layouts/Users/BizmixLayout';

export const BizmixRoutes = [
  {
    exact: true,
    path: '/bizmix',
    layout: BizmixLayout,
    component: lazy(() => import('../Views/Bizmix/BizmixIndexPage'))
  },
  {
    exact: true,
    path: '/bizmixer',
    layout: BizmixLayout,
    component: lazy(() => import('../Views/Bizmix/BizmixIndexPage'))
  },
  {
    exact: true,
    layout: BizmixLayout,
    path: '/bizmix/gallery',
    component: lazy(() => import('../Views/Bizmix/BizmixGalleryPage'))
  },
  {
    exact: true,
    layout: BizmixLayout,
    path: '/bizmix/signup',
    component: lazy(() => import('../Views/Bizmix/BizmixRegistrationPage'))
  },
  {
    exact: true,
    layout: BizmixLayout,
    path: '/bizmix/survey',
    component: lazy(() => import('../Views/Bizmix/BizmixSurveyPage'))
  },
  {
    exact: true,
    layout: BizmixLayout,
    path: '/bizmix/payment',
    component: lazy(() => import('../Views/Bizmix/BizmixPaymentPage'))
  },
  {
    exact: true,
    layout: BizmixLayout,
    path: '/bizmix/success',
    component: lazy(() => import('../Views/Bizmix/BizmixSuccessPage'))
  }
];
